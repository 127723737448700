.footer {
  width: 100%;
  min-width: 1440px;
  height: 400px;
  background-color: #fff;

  >.footer_w {
    box-sizing: border-box;
    width: 1440px;
    height: 100%;
    margin: 0 auto;
    padding-top: 92px;

    >.footer_w_content {
      width: 1144px;
      height: 230px;
      margin: 0 auto 26px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      >.f_w_c_logo {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        height: 100%;

        >img {
          width: 163px;
          height: 160px;
        }

        >.f_w_c_paper {
          width: 130px;
          height: 36px;
          border-radius: 10px;
          text-align: center;
          line-height: 36px;
          cursor: pointer;
          background-color: #000000;
          color: #fff;
          text-decoration: none;
        }
      }

      >.f_w_c_contact {
        width: 530px;

        >.f_w_c_c_title {
          color: #000000;
          font-size: 20px;
          line-height: 26px;
          font-weight: 700;
          text-align: center;
          margin-bottom: 32px;
        }

        >.f_w_c_c_links {
          display: flex;
          justify-content: space-between;
          transform: translateX(36px);

          >a {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-decoration: none;

            &:last-child {
              text-decoration: underline;
              text-decoration-color: #666666;
            }

            >img {
              width: 40px;
              height: 32px;
              margin-bottom: 32px;
            }

            >p {
              font-size: 14px;
              line-height: 20px;
              color: #666666;
            }
          }
        }
      }

      >.f_w_c_partner {
        >.f_w_c_p_title {
          color: #000000;
          font-size: 20px;
          line-height: 26px;
          font-weight: 700;
          text-align: center;
          margin-bottom: 18px;
        }

        >a {
          display: flex;
          flex-direction: column;
          align-items: center;
          text-decoration: none;

          >img {
            width: 62px;
            height: 60px;
            margin-bottom: 18px;
          }

          >p {
            font-size: 14px;
            line-height: 20px;
            color: #666666;
          }
        }
      }
    }

    > .footer_w_copyright {
      width: 1130px;
      margin: 0 auto;
      padding-top: 8px;
      font-size: 16px;
      color: #cccccc;
      line-height: 40px;
      text-align: center;
      border-top: 1px solid #f1f1f1;
    }
  }
}