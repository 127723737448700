.home_index {
  width: 1440px;
  height: 780px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;

  >.home_index_content {
    position: relative;
    width: 100%;
    height: 480px;
    margin-top: -100px;

    >.h_i_c_bg {
      position: absolute;
      width: 850px;
      height: 480px;
      left: 0;
      right: 0;
      margin: 0 auto;
      background: radial-gradient(ellipse closest-side,
          #b073ff,
          #ffffff,
          #060606);
      opacity: 0.1;
    }

    >.h_i_c_logo {
      position: absolute;
      width: 320px;
      height: 442px;
      top: 0;
      left: 0;
      right: 0;
      margin: 0 auto;
      background-image: url("@img/home/logo.png");
      background-size: 320px 442px;

      >img {
        width: 100%;
        height: 100%;
      }
    }

    >.h_i_c_text {
      position: absolute;
      top: 108px;
      left: 0;
      right: 0;
      margin: 0 auto;

      >img {
        display: block;
        margin: 0 auto;
        margin-bottom: 20px;
      }

      >h6 {
        height: 30px;
        text-align: center;
        font-size: 28px;
        font-weight: 700;
        line-height: 1;
        margin-bottom: 74px;
        overflow: hidden;
      }

      >div {
        height: 26px;
        text-align: center;
        font-size: 24px;
        line-height: 1;
        overflow: hidden;
      }
    }

    >.h_i_c_buttons {
      position: absolute;
      top: 462px;
      left: 0;
      right: 0;
      margin: 0 auto;
      width: 394px;
      display: flex;
      margin: 0 auto;
      justify-content: space-between;

      >a {
        display: block;
        width: 130px;
        height: 36px;
        border-radius: 10px;
        font-weight: 700;
        text-align: center;
        line-height: 36px;
        cursor: pointer;
        color: #fff;
        text-decoration: none;
        border: 1px solid #fff;
      }

      >.h_i_c_b_launch {
        background-color: #fff;
        color: #000000;
      }
    }
  }

  >.home_index_arrow {
    position: absolute;
    width: 10px;
    height: 12px;
    bottom: 36px;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 99;
    animation: bounce-inSS 2s infinite;
    /* 启动动画特效 */
    cursor: pointer;

    >img {
      width: 100%;
      height: 100%;
    }
  }
}