@import './config.less';

* {
  margin: 0;
  padding: 0;
  border: 0;
}

body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
input,
button,
textarea,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0;
}

body {
  background-color: @bgColor;
  color: @mainColor;
  font-size: @mainFontSize;
  font-family: @mainFont;
  color: #fff;
}

td,
th,
caption {
  font-size: @mainFontSize;
}

a {
  color: @mainLinkColor;
  cursor: pointer;
}

img {
  border: none;
  display: block;
}

ol,
ul,
li {
  list-style: none;
}

input,
textarea {
  outline: none;
}

table {
  border-collapse: collapse;
}

ul,
li {
  list-style: none;
}

.clearfix {
  clear: both;
  zoom: 1;
}

.clearfix:after {
  content: ' ';
  height: 0;
  display: block;
  line-height: 0;
  clear: both;
  visibility: hidden;
}

// 修改组件库默认样式
.ant-pagination {
  text-align: center;
  margin-top: 20px;
}

// 公用表单元素样式
.search_conaiter {
  .ant-form-item {
    margin-bottom: 15px;
    margin-right: 20px;
  }
  // 默认显示6个字
  .ant-form-item-label > label {
    width: 100px !important;
  }
  .ant-input {
    width: 200px !important;
  }
  .ant-select {
    width: 200px !important;
  }
  .ant-input-affix-wrapper {
    width: 200px !important;
    .ant-input {
      width: auto !important;
    }
  }
  // 显示4个字
  &.words_4 {
    .ant-form-item-label > label {
      width: 70px !important;
    }
  }
}

// 分割标题
.divider_left {
  position: relative;
  .ant-divider-inner-text {
    font-weight: bold;
    padding-left: 0;
    font-size: 14px;
  }

  &::before {
    width: 0 !important;
  }
  .divider_btn {
    position: absolute;
    right: 0;
    top: -5px;
    z-index: 9;
    background: #ffffff;
    > button {
      margin-left: 10px;
    }
  }
}

// 页面loading样式重置
.ant-spin-blur {
  opacity: 1;
}
.ant-spin-container::after {
  background: transparent;
}

.margin_top20 {
  margin-top: 20px;
}

// 表单item提示文本
.ant-form-item-explain,
.ant-form-item-extra {
  font-size: 12px;
}

// 无分页表格样式
.nopage_table {
  .ant-pagination {
    display: none;
  }
}

.mgr_20 {
  margin-right: 20px;
}

.ant-spin-nested-loading > div > .ant-spin {
  z-index: 2000 !important;
}

.page_header_content {
  padding-top: 15px;
}
