@headerHeight: 120px;
@headerWidth: 1440px;

.header {
  min-width: @headerWidth;
  height: @headerHeight;
  background-color: #060606;

  >.header_w {
    box-sizing: border-box;
    width: @headerWidth;
    height: 100%;
    margin: 0 auto;
    padding: 0 74px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    > .header_w_logo {
      width: 148px;
      height: 56px;
      > img {
        width: 100%;
        height: 100%;
      }
    }

    >.header_w_buttons {
      display: flex;
      align-items: center;

      > .h_w_b_router {
        display: flex;
        margin-top: -9px;
        margin-right: 84px;
        > li {
          display: flex;
          align-items: flex-end;
          font-size: 16px;
          font-weight: 700;
          position: relative;
          &:hover {
            > a {
              color: #fff;
            }
          }
          &:after {
            display: block;
            content: '';
            position: absolute;
            bottom: -6px;
            left: 0;
            width: 100%;
            height: 2px;
            background-color: #525252;
          }
          &:first-child {
            > a {
              margin-left: 8px;
            }
          }
          &:last-child:after {
            width: 76%;
          }
          &.h_w_b_r_current {
            > a {
              color: #fff;
              position: relative;
              &:after {
                display: block;
                content: '';
                position: absolute;
                bottom: -6px;
                left: -4px;
                width: 100%;
                height: 2px;
                background-color: #fff;
              }
            }
            &:after {
              display: none;
            }
          }
          > span {
            display: inline-block;
            height: 22px;
            color: #979797;
          }
          > a {
            margin: 0 20px;
            color: #979797;
            text-decoration: none;
          }
        }
      }
      >.h_w_b_lang {
        position: relative;

        >img {
          cursor: pointer;
        }

        >.h_w_b_l_pop {
          position: absolute;
          top: 38px;
          right: 0;
          width: 160px;
          height: 88px;
          box-sizing: border-box;
          padding: 8px;
          background-color: #000000;
          border: 1px solid #292929;
          border-radius: 16px;
          z-index: 99;

          >li {
            text-align: center;
            height: 50%;
            line-height: 35px;
            color: #fff;
            cursor: pointer;
            border-radius: 10px;

            &:hover {
              background-color: #191919;
            }

            &.h_w_b_l_p_current {
              background-color: #191919;
            }
          }
        }
      }
    }
  }
}